export const menuItems = [
    /*{
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },*/
    {
        id: 2,
        label: 'Painel',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'Associados',
        icon: 'ri-profile-line',
        link: '/associados'
    },
    {
        id:4,
        label: 'Planos',
        icon: 'ri-eraser-fill',
        link: '/planos'
    },
    {
        id: 5,
        label: 'Admins',
        icon: 'ri-profile-line',
        link: '/admins'
    }
]